@import "static/fonts/dankmono.css";

html {
  height: 100%
}

body {
  margin: 0;
  height: 100%;
  background-color: #282a36;
  font-family: "dm", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.react-root {
  height: 100%;
}
